export const TRUSTPILOT_WIDGET_LANGUAGE_LOCALE_MAP = {
  en: 'en-US',
  es: 'es-ES',
  de: 'de-DE',
  pt: 'pt-BR',
  fr: 'fr-FR',
  it: 'it-IT',
  nl: 'nl-NL',
} as const;

export const TRUSTPILOT_TEMPLATE_ID = '53aa8807dec7e10d38f59f32';
export const TRUSTPILOT_BUSINESS_UNIT_ID = '59935d1d0000ff0005a8efd0';
